export default {
  namespaced: true,
  state: {},
  getters: {
    currentCategory: state => state.currentCategory || '-',
  },
  mutations: {
    UPDATE_DYNAMIC_BREADCRUMBS(state, val) {
      state.currentCategory = val
    },
  },
  actions: {},
}
