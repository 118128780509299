// import store from '@/store'

export default [
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('@/views/analytics/List.vue'),
    meta: {
      pageTitle: 'Аналитика',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Аналитика',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/categories/parent/:id',
  //   name: 'categories-parent',
  //   component: () => import('@/views/categories/List.vue'),
  //   props: true,
  //   meta: {
  //     pageTitle: 'Список категорий',
  //     requiresAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Список категорий',
  //         active: false,
  //         to: '/categories',
  //       },
  //       {
  //         text: 'Категории родителя',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/analytics/create',
    name: 'analytics-create',
    component: () => import('@/views/analytics/Create.vue'),
    props: true,
    meta: {
      pageTitle: 'Создание ПКО/РКО',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Аналитика',
          active: false,
          to: '/analytics',
        },
        {
          text: 'Создание',
          active: true,
        },
      ],
    },
  },
  {
    path: '/analytics/update/:id',
    name: 'analytics-update',
    component: () => import('@/views/analytics/Update.vue'),
    props: true,
    meta: {
      pageTitle: 'Аналитика',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Аналитика',
          active: false,
          to: '/analytics',
        },
        {
          text: 'Редактирование',
          active: true,
        },
      ],
    },
  },

  {
    path: '/analytics/show/:id',
    name: 'analytics-show',
    component: () => import('@/views/analytics/ShowDay.vue'),
    props: true,
    meta: {
      pageTitle: 'Аналитика',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Аналитика',
          active: false,
          to: '/analytics',
        },
        {
          text: 'Просмотр за выбранный день',
          active: true,
        },
      ],
    },
  },
]
