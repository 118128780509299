// import store from '@/store'

export default [
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/categories/List.vue'),
    meta: {
      pageTitle: 'Список категорий',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Список категорий',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/parent/:id',
    name: 'categories-parent',
    component: () => import('@/views/categories/List.vue'),
    props: true,
    meta: {
      pageTitle: 'Список категорий',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Список категорий',
          active: false,
          to: '/categories',
        },
        {
          text: 'Категории родителя',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/create',
    name: 'categories-create',
    component: () => import('@/views/categories/Create.vue'),
    props: true,
    meta: {
      pageTitle: 'Создание категории',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Список категорий',
          active: false,
          to: '/categories',
        },
        {
          text: 'Создание',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/update/:id',
    name: 'categories-update',
    component: () => import('@/views/categories/Update.vue'),
    props: true,
    meta: {
      pageTitle: 'Редактирование категории',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Список категорий',
          active: false,
          to: '/categories',
        },
        {
          text: 'Редактирование',
          active: true,
        },
      ],
    },
  },
]
