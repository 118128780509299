export default [
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      pageTitle: 'Какая-то страница',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Другая страница',
          active: true,
        },
      ],
    },
  },
]
