// import store from '@/store'

export default [
  {
    path: '/kassa',
    name: 'kassa',
    component: () => import('@/views/kassa/List.vue'),
    meta: {
      pageTitle: 'Касса',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Касса',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/categories/parent/:id',
  //   name: 'categories-parent',
  //   component: () => import('@/views/categories/List.vue'),
  //   props: true,
  //   meta: {
  //     pageTitle: 'Список категорий',
  //     requiresAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Список категорий',
  //         active: false,
  //         to: '/categories',
  //       },
  //       {
  //         text: 'Категории родителя',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/kassa/create',
    name: 'kassa-create',
    component: () => import('@/views/kassa/Create.vue'),
    props: true,
    meta: {
      pageTitle: 'Создание ПКО/РКО',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Касса',
          active: false,
          to: '/kassa',
        },
        {
          text: 'Создание',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kassa/update/:id',
    name: 'kassa-update',
    component: () => import('@/views/kassa/Update.vue'),
    props: true,
    meta: {
      pageTitle: 'Касса',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Касса',
          active: false,
          to: '/kassa',
        },
        {
          text: 'Редактирование',
          active: true,
        },
      ],
    },
  },

  {
    path: '/kassa/show/:id',
    name: 'kassa-show',
    component: () => import('@/views/kassa/ShowDay.vue'),
    props: true,
    meta: {
      pageTitle: 'Касса',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Касса',
          active: false,
          to: '/kassa',
        },
        {
          text: 'Просмотр за выбранный день',
          active: true,
        },
      ],
    },
  },
]
