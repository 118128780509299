// import store from '@/store'

export default [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orders/List.vue'),
    meta: {
      pageTitle: 'Заказы',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Заказы',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/create',
    name: 'orders-create',
    component: () => import('@/views/orders/Create.vue'),
    props: true,
    meta: {
      pageTitle: 'Создание заказа',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Заказы',
          active: false,
          to: '/orders',
        },
        {
          text: 'Создание',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/update/:id',
    name: 'orders-update',
    component: () => import('@/views/orders/Update.vue'),
    props: true,
    meta: {
      pageTitle: 'Заказы',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Заказы',
          active: false,
          to: '/orders',
        },
        {
          text: 'Редактирование',
          active: true,
        },
      ],
    },
  },

  {
    path: '/orders/show/:id',
    name: 'orders-show',
    component: () => import('@/views/kassa/ShowDay.vue'),
    props: true,
    meta: {
      pageTitle: 'Заказы',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Заказы',
          active: false,
          to: '/orders',
        },
        {
          text: 'Просмотр',
          active: true,
        },
      ],
    },
  },

  {
    path: '/orders/duplicate/:id',
    name: 'orders-duplicate',
    component: () => import('@/views/orders/Duplicate.vue'),
    props: true,
    meta: {
      pageTitle: 'Заказы',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Заказы',
          active: false,
          to: '/orders',
        },
        {
          text: 'Дублирование',
          active: true,
        },
      ],
    },
  },
]
