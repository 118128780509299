export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/homepage2/Calendar.vue'),
    meta: {
      pageTitle: 'Календарь предварительной записи',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Календарь предварительной записи',
          active: true,
        },
      ],
    },
  },
]
