import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: localStorage.getItem('vuexy-verticalMenu') || $themeConfig.layout.menu.isCollapsed,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
      console.log(val)
      if (val) {
        localStorage.setItem('vuexy-verticalMenu', 1)
      } else {
        localStorage.removeItem('vuexy-verticalMenu')
      }
    },
  },
  actions: {},
}
