// import store from '@/store'

export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/List.vue'),
    meta: {
      pageTitle: 'Пользователи',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Пользователи',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: () => import('@/views/users/Create.vue'),
    props: true,
    meta: {
      pageTitle: 'Создание пользователя',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Пользователи',
          active: false,
          to: '/users',
        },
        {
          text: 'Создание',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/update/:id',
    name: 'users-update',
    component: () => import('@/views/users/Update.vue'),
    props: true,
    meta: {
      pageTitle: 'Пользователи',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Пользователи',
          active: false,
          to: '/kassa',
        },
        {
          text: 'Редактирование',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/show/:id',
    name: 'users-show',
    component: () => import('@/views/kassa/ShowDay.vue'),
    props: true,
    meta: {
      pageTitle: 'Пользователи',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Пользователи',
          active: false,
          to: '/users',
        },
        {
          text: 'Просмотр',
          active: true,
        },
      ],
    },
  },
]
